import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import ExLinkCard from '../middle/ExLinkCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ExLinksBox: {
      padding: `1rem 0`,
      marginTop: `-2rem`,
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 20%, ${theme.palette.background.default} 50%, ${theme.palette.background.default} 100%)`,
    },
    cardsWrapper: {
      maxWidth: `1200px`,
      margin: `2rem auto`,
      width: `90%`,
    },
  }),
);

const ExLinks: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.ExLinksBox}>
      <Grid container className={classes.cardsWrapper} spacing={3}>
        <Grid item xs={12} lg={6}>
          <ExLinkCard
            title="JDL IBEX 出納帳 Major"
            link="http://www.jdlibex.net/ab-major/"
            content="当事務所でおすすめしている会計ソフトです。書類いらずで簡単に会計データの受け渡しができます。"
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default ExLinks;
